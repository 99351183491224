import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import navigate from '../utils/navigate'
import { Context as LeaveContext } from './LeaveContext'

export default function PrivateRoute({ children, RequiredStep }) {
  const LeaveRequestState = useContext(LeaveContext)
  if (!LeaveRequestState[RequiredStep]) {
    navigate('/Overview/')
    return null
  }

  return (
    <>
      {children}
    </>
  )
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  RequiredStep: PropTypes.string.isRequired,
}
