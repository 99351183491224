import React, { useContext, useState, useEffect } from 'react'
import InputMask from 'react-input-mask'
import { navigate, Link } from 'gatsby'
import { Context as LeaveContext } from '../components/LeaveContext'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PrivateRoute from '../components/PrivateRoute'
import StepRoute from '../components/StepRoute'
import LeaveRequestQuickNav from '../components/LeaveRequestQuickNav'
import getAnimationStyle from '../utils/getAnimationStyle'

export default function New_Leave_Request_step2() {
  const { Manager, stepTwo } = useContext(LeaveContext)

  const [state, setState] = useState({
    FullName: '',
    Title: '',
    EmailAddress: '',
    Phone: '',
    IsNotifiedManager: '',
    Error: {},
  })

  useEffect(() => {
    if (!Manager) {
      navigate('/Overview/')
    } else {
      setState(prev => ({
        ...prev,
        FullName: Manager.FullName,
        Title: Manager.Title,
        EmailAddress: Manager.EmailAddress,
        Phone: Manager.Phone,
        IsNotifiedManager: Manager.IsNotifiedManager
          ? Manager.IsNotifiedManager
          : '',
        Error: {},
      }))
    }
  }, [Manager])

  const onSubmit = (event) => {
    event.preventDefault()

    const Errors = {}
    // Validate Data
    if (state.FullName.length < 2) Errors.FullName = 'Name is not valid'

    if (state.Title.length < 2) Errors.Title = 'Title is not valid'

    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!re.test(String(state.EmailAddress).toLowerCase())) {
      Errors.EmailAddress = 'Email is not valid'
    }

    re = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s.]{0,1}[0-9]{3}[-\s.]{0,1}[0-9]{4}$/
    if (!re.test(String(state.Phone).toLowerCase())) {
      Errors.Phone = 'Phone is not valid'
    }

    if (state.IsNotifiedManager !== 'Yes' && state.IsNotifiedManager !== 'No') {
      Errors.IsNotifiedManager = 'Manager Notification is not valid'
    }

    // Check for errors
    if (Object.keys(Errors).length > 0) {
      setState(prev => ({ ...prev, Error: Errors }))
      return false
    }

    // Remove Errors before saving to store
    const newManagerData = { ...state }
    delete newManagerData.Error

    // Save to store
    stepTwo(newManagerData)
    navigate('/New_Leave_Request_step3/')
  }
  const handleFullNameChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.FullName
    setState(prev => ({ ...prev, FullName: e.target.value, Error }))
  }
  const handleTitleChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.Title
    setState(prev => ({ ...prev, Title: e.target.value, Error }))
  }
  const handleEmailAddressChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.EmailAddress
    setState(prev => ({ ...prev, EmailAddress: e.target.value, Error }))
  }
  const handlePhoneChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.Phone
    setState(prev => ({ ...prev, Phone: e.target.value, Error }))
  }
  const handleIsNotifiedManagerChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.IsNotifiedManager
    setState(prev => ({ ...prev, IsNotifiedManager: e.target.value, Error }))
  }

  return (
    <PrivateRoute>
      <StepRoute RequiredStep="stepOneComplete">
        <Layout activateLink>
          <SEO title="Leave Request Step 2" />
          <div className="content-outline-998 leaveForm">
            <LeaveRequestQuickNav />
            <p>&nbsp;</p>
            <p className="clear">&nbsp;</p>
            <p className="clear">&nbsp;</p>
            <div className="questionhdr">
              Please review and confirm your Manager&apos;s Information:
              <br />
            </div>
            <form className="LeaveFormEntry" onSubmit={onSubmit}>
              <div
                className={state.Error.FullName ? 'error formDiv' : 'formDiv'}
                style={getAnimationStyle(0)}
              >
                <label>Name:&nbsp;&nbsp;</label>
                <input
                  name="ctl00$Holder$Request01$ManagerName"
                  type="text"
                  id="ctl00_Holder_Request01_ManagerName"
                  onChange={handleFullNameChange}
                  value={state.FullName}
                />
              </div>
              <div
                className={state.Error.Title ? 'error formDiv' : 'formDiv'}
                style={getAnimationStyle(1)}
              >
                Title:&nbsp;&nbsp;
                <input
                  type="text"
                  name="ctl00$Holder$Request01$ManagerTitle"
                  id="ctl00_Holder_Request01_ManagerTitle"
                  onChange={handleTitleChange}
                  value={state.Title}
                />
              </div>
              <br className="clear" />
              <div
                className={state.Error.EmailAddress ? 'error formDiv' : 'formDiv'}
                style={getAnimationStyle(2)}
              >
                <label>Email Address:&nbsp;&nbsp;</label>
                <input
                  type="email"
                  name="ctl00$Holder$Request01$txtEmail"
                  id="ctl00_Holder_Request01_txtEmail"
                  style={{ width: '16em' }}
                  onChange={handleEmailAddressChange}
                  value={state.EmailAddress}
                />
              </div>
              <br className="clear" />
              <div
                className={state.Error.Phone ? 'error formDiv' : 'formDiv'}
                style={getAnimationStyle(3)}
              >
                <label>Phone:&nbsp;&nbsp;</label>
                <InputMask
                  mask="(999) 999-9999"
                  maskChar=" "
                  alwaysShowMask
                  type="tel"
                  name="ctl00$Holder$Request01$ManagerPhone"
                  id="ctl00_Holder_Request01_ManagerPhone"
                  onChange={handlePhoneChange}
                  value={state.Phone}
                  style={{ width: '7.5em' }}
                />
              </div>
              <br className="clear" />
              <div
                className={
                  state.Error.IsNotifiedManager ? 'error formDiv' : 'formDiv'
                }
                style={getAnimationStyle(4)}
              >
                <label>
                  Have you notified your manager of your intention to request a
                  leave of absense?&nbsp;&nbsp;
                </label>
                <select
                  name="ctl00$Holder$Request01$ddlMngrNotify"
                  id="ctl00_Holder_Request01_ddlMngrNotify"
                  style={{ width: '2.25em' }}
                  onChange={handleIsNotifiedManagerChange}
                  value={state.IsNotifiedManager}
                >
                  <option value="" />
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                <br />
                <span className="notation">
                  (Note: Your manager will be notified of the dates your leave of
                  absence once you submit your request.)
                </span>
              </div>
              <div className="errorMsgs">
                {Object.keys(state.Error).map(x => (
                  <span key={state.Error[x]}>
                    {state.Error[x]}
                    <br />
                  </span>
                ))}
              </div>
              <p className="clear">&nbsp;</p>
              <div id="button" className="floatleft">
                <Link to="/New_Leave_Request_step1/">« Previous Step</Link>
              </div>
              <input type="submit" id="setp1submit" value="Continue &raquo;" style={{ cursor: 'pointer' }} />
            </form>
          </div>
        </Layout>
      </StepRoute>
    </PrivateRoute>
  )
}
